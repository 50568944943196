import React from "react";
import Fade from "react-reveal";
import "./styles.css";

import I1 from "../../Assets/images/os1.png";
import I2 from "../../Assets/images/os2.png";
import I3 from "../../Assets/images/os3.png";
import I4 from "../../Assets/images/os4.png";
import I5 from "../../Assets/images/os5.png";
import I6 from "../../Assets/images/os6.png";
import I7 from "../../Assets/images/os7.png";
import I8 from "../../Assets/images/os8.png";
import I9 from "../../Assets/images/os9.png";

const Services = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <p className="pcr-section-header">Our Services</p>
        </div>
        <div className="col-md-8">
          <div className="pcr-section-desc">
            Let’s work together with you to create or transform you brand.
          </div>
        </div>
      </div>
      {/* <div className="services-bg ">
        <div>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi,
          dolorum laborum amet quod impedit, eveniet repudiandae praesentium
          iure quidem dicta rem quo vero cupiditate, et aut voluptatem?
          Corporis, odit ipsam?
        </div>
      </div> */}
      <div className="row mt-5">
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I1} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>DESIGN</p>
                <span>Graphic Design </span>
                <span>Web Design</span>
                <span>App Design</span>
                <span>Pitch Deck</span>
                <span>Brand Style Guide</span>
                <span>Prototyping/Wireframe</span>
                <span>User Interface Design</span>
                <span>User Experience Design</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I2} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>DEVELOPMENT</p>
                <span>SEO</span>
                <span>Project Management</span>
                <span>Front End Web Development</span>
                <span>Back End Web Development</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I3} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>BRANDING</p>
                <span>Event Branding</span>
                <span>Business Branding</span>
                <span>Product Branding</span>
                <span>Motion Graphics</span>
                <span>Graphics Design</span>
                <span>Branding Guide Development</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I4} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>CONTENT CREATION</p>
                <span>Story Telling</span>
                <span>Article Writing</span>
                <span>Brand Profiling</span>
                <span>Company Profiling</span>
                <span>Product Contenting</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I5} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>MARKETING</p>
                <span>SEO</span>
                <span>Product Shoot</span>
                <span>E-Flyer Design</span>
                <span>Social Media Management</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I6} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>COURSES/INTERNSHIP/TRAINING</p>
                <span>App Design</span>
                <span>Web Design</span>
                <span>Motion Graphics</span>
                <span>Brand Guide Design</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I7} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>RESEARCH</p>
                <span>Market Research</span>
                <span>Customer Research</span>
                <span>Brand Research</span>
                <span>Industry Research</span>
                <span>Company Research</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I8} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>PRODUCT PRODUCTION/SALE</p>
                <span>Patch Gown</span>
                <span>Water Flask</span>
                <span>Face Towel</span>
                <span>Tissue Bag</span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="col-md-4">
            <div className="pcr-services-wrapper">
              <img src={I9} alt="ProCr8 Hub Design" className="w-100" />
              <div className="pcr-section-list">
                <p>PHOTOGRAPHY/ CINEMATOGRAPHY</p>
                <span>Product Shoot</span>
                <span>Product MKT Video</span>
                <span>Pitch Deck Shoot</span>
                <span>Wedding Shoot</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Services;
