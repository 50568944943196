import React from "react";
import Nav from "../Components/NavBar";
import Footer from "../Components/Footer";
import Comp from "../Components/Portfolio";
import PageTitle from "../Components/PageTitle";

const Portfolio = () => {
  return (
    <div>
      <PageTitle title="Procr8hub | Portfolio" />
      <Nav />
      <Comp />
      <Footer />
    </div>
  );
};

export default Portfolio;
