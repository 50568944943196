import React from "react";
import Comp from "../Components/Marketing";
import Nav from "../Components/NavBar";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle";

const Marketing = () => {
  return (
    <div>
      <PageTitle title="Procr8hub | Marketing" />
      <Nav />
      <Comp />
      <Footer />
    </div>
  );
};

export default Marketing;
