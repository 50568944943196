import React from "react";
import Comp from "../Components/Portfolio";
const GetStarted = () => {
  return (
    <div>
      <Comp />
    </div>
  );
};

export default GetStarted;
