import React, { useState } from "react";
import "./styles.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
// import LoaderComp from "../../components/Loader";
import axios from "axios";
import { API_URL } from "../../constants";

export default function Form(props) {
  const [input, setInput] = useState({
    email: "",
    name: "",
    number: "",
    projectName: "",
    overview: "",
    briefDoc: null,
    briefNnote: "",
    timeframe: "",
    budget: "",
    others: "",
  });
  const [services, setServices] = useState({
    branding: false,
    mobile: false,
    web: false,
    social: false,
    graphic: false,
    uiux: false,
    content: false,
    copy: false,
    productBranding: false,
    software: false,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = (e) => {
    setError("");
    e.preventDefault();
    const {
      email,
      name,
      number,
      overview,
      briefDoc,
      briefNnote,
      timeframe,
      budget,
      projectName,
      others,
    } = input;
    if (
      !email ||
      !name ||
      !number ||
      !overview ||
      !timeframe ||
      !budget ||
      !projectName
    ) {
      setError("Please kindly fill the required field");
    } else if (!briefNnote && !briefDoc) {
      setError("Project Brief (a) and Project Brief (b) cannot be empty");
    } else if (
      !services.branding &&
      !services.content &&
      !services.copy &&
      !services.graphic &&
      !services.mobile &&
      !services.productBranding &&
      !services.social &&
      !services.uiux &&
      !services.web &&
      !services.software
    ) {
      setError("At least a checkbox must be checked");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("name", name);
      formData.append("number", number);
      formData.append("others", others);
      formData.append("projectName", projectName);
      formData.append("overview", overview);
      briefDoc && formData.append("briefDoc", briefDoc);
      briefNnote.length > 0 && formData.append("briefNote", briefNnote);
      formData.append("timeframe", timeframe);
      formData.append("budget", budget);
      services.branding && formData.append("branding", services.branding);
      services.mobile && formData.append("mobile", services.mobile);
      services.web && formData.append("web", services.web);
      services.social && formData.append("social", services.social);
      services.graphic && formData.append("graphic", services.graphic);
      services.uiux && formData.append("uiux", services.uiux);
      services.content && formData.append("content", services.content);
      services.copy && formData.append("copy", services.copy);
      services.productBranding &&
        formData.append("productBranding", services.productBranding);
      services.software && formData.append("software", services.software);
      axios
        .post(`${API_URL}/project`, formData)
        .then((_res) => {
          // console.log(res)
          setError("Thank you for filling the form. We'll reach out ot you");
          setLoading(false);
          // props.history.push(`/confirmation/name=${input.name}`);
        })
        .catch((_e) => {
          setLoading(false);
          setError("An Error Occured! Try Again.");
        });
    }
  };
  return (
    <>
      <Container fluid className="whitebg formwrapper pb-5">
        <Container>
          <Row className="mt-5">
            <Col
              md={{ span: 8, offset: 1 }}
              className="a-team-card-holder pushtop mt-5"
            >
              <p className="montserrat blackText b4 mt-5">
                Good day client. We have tailored this form to help us
                understand how to serve you. In the event that you do not have a
                project brief, we can document one for you at a very affordable
                rate. Whichever way, kindly fill this form correctly and
                accurately.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }} className="a-team-card-holder">
              <form className="mt-5 pb-5 montserrat">
                <Row className="mt-4">
                  <Col>
                    <p className="blackText bold">Email Address</p>
                    <input
                      type="email"
                      value={input.email}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, email: e.target.value });
                      }}
                      placeholder="youremail@domain.com"
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <p className="blackText bold mt-4">Your Name</p>
                    <input
                      type="text"
                      value={input.name}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, name: e.target.value });
                      }}
                      placeholder="Your Name or Company Name"
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <p className="blackText bold mt-4">Phone Number</p>
                    <input
                      // type="tel"
                      value={input.number}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, number: e.target.value });
                      }}
                      type="number"
                      placeholder="+123 4567 890"
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col>
                    <p className="blackText bold">Project Name</p>
                    <input
                      type="text"
                      value={input.projectName}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, projectName: e.target.value });
                      }}
                      placeholder="E.g Motivv App"
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <p className="blackText bold">Project Overview</p>
                    <textarea
                      value={input.overview}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, overview: e.target.value });
                      }}
                      className="blackinput smallestText textarea adjust"
                      placeholder="E.g. The Motivv App is aimed to bridge the gap between clients and designers"
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12}>
                    <p className="blackText bold mb-3">Project Brief (a)</p>
                    <p className="blackText smallestText">
                      Upload a more detailed documentation of your project if
                      you have one
                    </p>
                  </Col>
                  <Col md={12}>
                    <input
                      type="file"
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, briefDoc: e.target.files[0] });
                      }}
                      className="file"
                      id="file"
                      accept="images/*, .pdf, .doc"
                      // required
                    />
                    <Col className="mt-5">
                      {input.briefDoc ? (
                        <label
                          htmlFor="file"
                          className="smallestText media label"
                        >
                          <span className="blackText upload mt-2">
                            {input.briefDoc.name}
                          </span>
                        </label>
                      ) : (
                        <label
                          htmlFor="file"
                          className="smallestText media label"
                        >
                          <span className="green b7 mt-2">Upload Media</span>{" "}
                          &nbsp;
                          <span className="blackText upload mt-2">
                            (jpeg, pdf, docx)
                          </span>
                        </label>
                      )}
                    </Col>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col>
                    <p className="blackText bold">Project Brief (b)</p>
                    <p className="blackText smallestText">
                      In the event of no file, kindly write your project brief.
                    </p>
                    <textarea
                      value={input.briefNnote}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, briefNnote: e.target.value });
                      }}
                      className="blackinput smallestText textarea adjust"
                      placeholder="With the Motivv App, we aim to bridge the gap between clients and designers and also..."
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col>
                    <p className="blackText bold">
                      Where Do You Want Us To Come In?
                    </p>
                    <p className="blackText smallestText">
                      Kindly select one or more services you want us to provide.
                    </p>

                    <div className="d-flex">
                      <span>
                        <input
                          onChange={() => {
                            setServices({
                              ...services,
                              branding: !services.branding,
                            });
                          }}
                          type="checkbox"
                          name="branding"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="branding"
                          className="blackText smallestText"
                        >
                          Branding
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.mobile}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              mobile: !services.mobile,
                            })
                          }
                          type="checkbox"
                          name="apps"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label for="apps" className="blackText smallestText">
                          Mobile App (Design and Development)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.web}
                          onChange={(e) =>
                            setServices({ ...services, web: !services.web })
                          }
                          type="checkbox"
                          name="webapp"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label for="webapp" className="blackText smallestText">
                          Web App (Design and Development)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.social}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              social: !services.social,
                            })
                          }
                          type="checkbox"
                          name="socialmedia"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="socialmedia"
                          className="blackText smallestText"
                        >
                          Social Media Management (Retainership and Design)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.graphic}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              graphic: !services.graphic,
                            })
                          }
                          type="checkbox"
                          name="graphicDesign"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="graphicDesign"
                          className="blackText smallestText"
                        >
                          Graphic Design (Design Subscription)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.uiux}
                          onChange={(e) =>
                            setServices({ ...services, uiux: !services.uiux })
                          }
                          type="checkbox"
                          name="uiux"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label for="uiux" className="blackText smallestText">
                          UI/UX Only ( User Story, Plan and Design )
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.content}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              content: !services.content,
                            })
                          }
                          type="checkbox"
                          name="contentCreation"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="contentCreation"
                          className="blackText smallestText"
                        >
                          Content Creation (Visual)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.copy}
                          onChange={(e) =>
                            setServices({ ...services, copy: !services.copy })
                          }
                          type="checkbox"
                          name="copyWriting"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="copyWriting"
                          className="blackText smallestText"
                        >
                          Copy Writing
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.software}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              software: !services.software,
                            })
                          }
                          type="checkbox"
                          name="softwareDev"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="softwareDev"
                          className="blackText smallestText"
                        >
                          Software Development (Improving, Rebuilding,
                          Maintenance)
                        </label>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span>
                        <input
                          value={services.productBranding}
                          onChange={(e) =>
                            setServices({
                              ...services,
                              productBranding: !services.productBranding,
                            })
                          }
                          type="checkbox"
                          name="productBranding"
                          className="service-options"
                        />
                      </span>
                      <span>
                        <label
                          for="productBranding"
                          className="blackText smallestText"
                        >
                          Product Branding, Strategy & Management <br />
                          (Kindly select if you don't have a team)
                        </label>
                      </span>
                    </div>

                    <input
                      type="text"
                      value={input.others}
                      onChange={(e) =>
                        setInput({ ...input, others: e.target.value })
                      }
                      placeholder="others"
                      className="blackinput smallestText mt-2"
                    />
                  </Col>
                  <div></div>
                </Row>

                <Row>
                  <Col md={6}>
                    <p className="blackText bold mt-4">Timeframe (Months)</p>
                    <input
                      type="text"
                      placeholder=""
                      value={input.timeframe}
                      onChange={(e) => {
                        setError("");
                        setInput({ ...input, timeframe: e.target.value });
                      }}
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <p className="blackText bold mt-4">Budget (₦)</p>
                    <input
                      type="number"
                      placeholder=""
                      value={input.budget}
                      onChange={(e) =>
                        setInput({ ...input, budget: e.target.value })
                      }
                      className="blackinput smallestText"
                      required
                    />
                  </Col>
                </Row>

                <button
                  type="submit"
                  onClick={onSubmit}
                  className="submitButton mt-5 black bold button"
                >
                  Submit
                </button>

                {!loading ? (
                  error ? (
                    <p
                      className="mt-3"
                      style={{
                        color:
                          error ===
                          "Thank you for filling the form. We'll reach out ot you"
                            ? "green"
                            : "red",
                        textAlign: "center",
                      }}
                    >
                      {error}
                    </p>
                  ) : null
                ) : (
                  <Spinner animation="border" variant="secondary" size="sm" />
                )}
              </form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
