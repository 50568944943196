import React from "react";
import Comp from "../Components/TermCondition";
import PageTitle from "../Components/PageTitle";

const TermCondition = () => {
  return (
    <div>
      <PageTitle title="Procr8hub | Term & Condition" />
      <Comp />
    </div>
  );
};

export default TermCondition;
