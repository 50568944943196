import React, { Component } from "react";
import Slider from "react-slick";

import B1 from "../../Assets/images/b1.png";
import B2 from "../../Assets/images/b2.png";
import B3 from "../../Assets/images/b3.png";
import B4 from "../../Assets/images/b4.png";
import B5 from "../../Assets/images/b5.png";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
    };
    return (
      <div className="container pcr-os-section remv">
        <h2 className="text-center">Why Us?</h2>
        <section className="text-center">
          We provide impeccablesolution serviceshat is UNIQUE to your brand,
          stands out and gets results that are beyond expectations
        </section>
        <Slider {...settings} className="mt-3">
          <div>
            <img src={B1} alt="" className="w-100" />
          </div>
          <div>
            <img src={B2} alt="" className="w-100" />
          </div>
          <div>
            <img src={B3} alt="" className="w-100" />
          </div>
          <div>
            <img src={B4} alt="" className="w-100" />
          </div>
          <div>
            <img src={B5} alt="" className="w-100" />
          </div>
        </Slider>
      </div>
    );
  }
}
