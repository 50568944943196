import React from "react";
import "./styles.css";
import NavBar from "../NavBar";
import Ill from "../../Assets/images/lp.png";
// import banner1 from "../../Assets/images/banner1.png";
// import banner1a from "../../Assets/images/banner1a.png";

const Home = () => {
  return (
    <div className="main-bg">
      <NavBar />
      {/* <main className="my-5">
        <div className="hero-section p-0 m-0 row">
          <div className="col-md-6">
            <div className="container">
              <HeaderTitle className="mb-5 text-center">
                A BRAND CREATED
                <br />
                JUST FOR YOU.
              </HeaderTitle>
              <img src={banner1a} height="350px" alt="procr8 banner" />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <img src={banner1} height="500px" alt="procr8 banner" />
          </div>
        </div>
      </main> */}
      <div className="container">
        <div className="pcr-landing-page-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="pcr-content-wrapper">
                <p className="pcr-landing-page-header">
                  Quality Digital Services
                  <br /> To Fit Your Needs.
                </p>
                <div>Get the best for your brand</div>
                <button className="button">Get Started</button>
              </div>
            </div>
            <div className="col-md-6">
              <img src={Ill} alt="" className="w-100 lp-illustration" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
