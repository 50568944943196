import React from "react";
import "./styles.css";
import Aboutus from "../../Assets/images/aboutus.png";
import ww1 from "../../Assets/images/ww1.png";
import ww2 from "../../Assets/images/ww2.png";
import ww3 from "../../Assets/images/ww3.png";
import www from "../../Assets/images/www.png";

const Portfolio = () => {
  return (
    <div className="container">
      <div className="container pcr-os-section">
        <h3 className="our-story">About us</h3>
        <p className="our-story-desc">
          We are a full-service branding, design and development company, we
          help you bring your personal brand ideas, event dreams and business
          ideas to life. We offer a complete range of creative services that
          inspire, influence, impact and convert at every touch point. Our
          services cut accross Content and Research, Corporate Branding,
          Business Branding, Product Branding and Packaging, Mobile App/Website
          Design and Development, Event Branding, Training, Internship, Design
          and Print Consultation. We work with every client from the ideation
          stage to Management. Well; talk is cheap, let’s work with you for a
          thrilling experience.
        </p>
      </div>
      <div className="mt-5">
        <img src={Aboutus} alt="" className="w-100" />
      </div>
      <h2 className="text-center mt-5 red">
        Reach your brand growth goals with our brand growth packages
      </h2>
      <div className="row push-top">
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <img src={ww1} alt="" />
            </div>
            <div className="col-8">
              <span className="bold-texts">
                Social Media Creation and Management
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <img src={ww2} alt="" />
            </div>
            <div className="col-8">
              <span className="bold-texts">
                Daily, Weekly or Monthly E-flyer Subscription
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <img src={ww3} alt="" />
            </div>
            <div className="col-8">
              <span className="bold-texts">
                Web/App Design, Development and Launch and Management
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="push-top">
        <div className="row tp">
          <div className="col-md-6">
            <img src={www} alt="" className="w-100" />
          </div>
          <div className="col-md-6">
            <p>Small business entrepreneurs</p>
            <p>Emerging Enterprises (Startups)</p>
            <p>One-man brands</p>
            <p>Corporate Organization </p>
            <p>(Private and Public Sectors)</p>
            <p>Freelancers</p>
            <p>Side hustlers</p>
            <p>Corporate escapees</p>
            <p>B2B & B2C companies</p>
            <p>Event Planners or managers</p>
            <p>Trainees</p>
            <p>Individuals</p>
            <a
              href="https://wa.me/2348093206395?text=Hello%20Procr8Hub%20I'm%20Interested%20in%20the%20Tutorials!"
              target="blank"
              className="whatsapp-wrapper"
            >
              <button className="pcr-get-started">Call / WhatsApp</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
