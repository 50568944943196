import React from "react";
import Fade from "react-reveal/Fade";
// import { Link } from "react-router-dom";
import "./styles.css";

import img1 from "../../Assets/images/descImg1.png";
import img2 from "../../Assets/images/descImg2.png";
import img3 from "../../Assets/images/descImg3.png";
import img4 from "../../Assets/images/descImg4.png";
import img5 from "../../Assets/images/descImg5.png";

const MarketingComp = () => {
  const items = [
    {
      img: img1,
      title: "SEO(Search Engine Optimization)",
      desc: "Targeting your potential audience and coverting them to client.",
    },
    {
      img: img2,
      title: "Social Media Management",
      desc: "Creating, Managing and Analysing your social media platforms.",
    },
    {
      img: img3,
      title: "Google Console",
      desc:
        "Using GSC collection of tools and resources to monitor website performances in the Google Search index.",
    },
    {
      img: img4,
      title: "E-mail Marketing ",
      desc:
        "Building your brand credibility through targeted and personalized e-mail contents.",
    },
    {
      img: img5,
      title: "Google My Business Ads",
      desc: "Showcasing your brand to reach a highly specified audience.",
    },
  ];
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <p className="pcr-section-header">Marketing</p>
        </div>
        <div className="col-md-8">
          <div className="pcr-section-desc">
            Move your brand forward through our brand growth strategy packages.
          </div>
        </div>
      </div>
      <div className="row">
        {items.map((item, i) => (
          <Fade>
            <div className="col-md-6 pcr-full-description-wrapper" key={i}>
              <div className="pcr-marketing-options">
                <div>
                  <img
                    src={item.img}
                    alt="ProCr8 Hub Projects"
                    className="pcr-section-img mobile-size"
                  />
                </div>
                <div className=" pcr-section-detail">
                  <p className="pcr-section-title">{item.title}</p>
                  <section>{item.desc}</section>
                  {/* <Link to="/project"> */}
                  <a href="Tel: 08093206395">
                    <button className="pcr-get-started button">
                      Get Started
                    </button>
                  </a>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default MarketingComp;
