import React from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";

const TermCondition = () => {
  return (
    <div>
      <NavBar />
      <div className="container my-5">
        <div>
          <h3 className="font-weight-bold">Definition of Terms&nbsp;</h3>
          <br />
          1. User Interface (UI) stands for User Interface. It majorly refers to
          the look, feel responsiveness, and interactiveness of a product.
          <br />
          2. User Experience (UX) means User Experience. This involves
          wireframing, strategy planning, and designing. <br />
          3. Branding Materials includes but is not limited to Logos, business
          cards, letterheads, flyers, brochures, brand guidelines, email
          templates, billboards, mugs, and other media as deemed fit. <br />
          4. Graphic Design refers to the process of visual communication and
          problem-solving, using tools of typography, iconography, and
          illustration. <br />
          5. Charges means fees agreed upon by ProCr8 Hub and the client body,
          as an equivalent value for services rendered. <br />
          6. Agreement means the entire content of this Basic Terms and
          Conditions form, the Proposal document(s), Engagement form, and every
          other supplement given below, together with any exhibits, schedules,
          or attachments hereafter. <br />
          7. Client Content means all materials, information, factual,
          promotional, or advertising claims or photography, writings, and other
          creative content provided or required by Client for use in the
          preparation and/or incorporation of the deliverables. <br />
          8. Copyrights mean the property rights in original works of
          authorship, expressed in a tangible medium of expression, as defined
          and enforceable under Nigeria's Copyright Law. <br />
          9. ProCr8 Hub Tools means all design tools developed or utilized by
          ProCr8 Hub in performing services, including without limitation,
          pre-existing and newly developed software comprising of source codes,
          Web authoring tools, type fonts, and application tools, and any other
          software or inventions, patentable or not, and general
          non-copyrightable concepts such as Website design, architecture,
          layout, navigational and functional elements.
          <br />
          10. Deliverables mean the services and work product specified in the
          Proposal to be delivered by ProCr8 Hub to a Client as specified in
          form and media, in the Proposal. <br />
          11. Final Deliverables means the final versions of Deliverables
          provided by ProCr8 Hub and accepted by the Client. <br />
          12. Final works means all creative content developed by ProCr8 Hub, or
          commissioned by ProCr8 Hub, exclusively for the Project and
          incorporated in the Final Deliverables, including, but not limited to,
          any and all visual elements, graphic design, illustration, animation,
          motion design, audio-visual works, sounds, typographic treatments and
          text, modifications to Client Content, and ProCr8 Hub&rsquo;s
          selection, arrangement and coordination of such elements together with
          Client Content and/or Third Party Materials. <br />
          13. Services mean all values and the products to be provided to the
          Client by ProCr8 Hub as described and agreed upon in the Proposal.
          <br />
          14. Project means the scope and purpose of the Client&rsquo;s usage of
          the work product as described in the Proposal. <br />
          Proposal <br />
          The terms of the Proposal shall be effective from seven (7) calendar
          days after presentation to the Client. In the event that this
          Agreement is not executed by Client within the time identified, the
          Proposal and other terms, conditions and deliverables, may be subject
          to amendment or substitution.
          <br />
          <h5 className="mt-2">Charges</h5>
          Fees. In consideration of the Services to be rendered by ProCr8 Hub,
          the Client shall pay to ProCr8 Hub, fees in the amounts agreed upon
          according to the payment schedule and method outlined in the Proposal.
          The client shall also pay all applicable sales, use, or value-added
          taxes as at when incurred, before, or after payment schedule. <br />
          Expenses. The client shall pay ProCr8 Hub&rsquo;s expenses incurred in
          connection with this Agreement as follows: (a) incidental and
          out-of-pocket expenses including but not limited to costs for
          telephone calls, postage, shipping, overnight courier et cetera.{" "}
          <br />
          Additional Costs. Project pricing includes ProCr8 Hub&rsquo;s fee
          only. Any additional costs including, but not limited to, equipment
          rental, photography costs, photography and/or artwork licenses,
          prototype production costs, talent fees, music licenses, and online
          access or hosting fees, will be billed to the Client unless
          specifically provided for in the Proposal. <br />
          Invoices/Payments. The client shall pay all invoices within seven (7)
          calendar days of the invoice date. A monthly service charge at a 1.5%
          rate may be charged on all overdue balances. <br />
          Payments will be credited first to late payment charges and next to
          the unpaid balances. ProCr8 Hub reserves the right to withhold
          Deliverables if accounts are not current, or overdue invoices are not
          paid in full. <br />
          <h5 className="mt-2"> Changes </h5>
          General Changes. Unless otherwise provided for in the Proposal, and
          except provided for herein, Client shall pay additional charges for
          changes requested outside the scope of the services rendered to the
          client on a time and materials basis, at ProCr8 Hub&rsquo;s standard
          hourly rate of 100$ per hour. <br />
          Such charges shall be in addition to all other amounts payable under
          the Proposal, despite any maximum budget, contract price, or final
          price identified therein. <br />
          ProCr8 Hub may extend or modify any delivery schedule or deadlines in
          the Proposal and Deliverables as may be required for such products.{" "}
          <br />
          Substantive Changes. If the Client requests or instructs changes that
          amount to a revision in or near excess of 10 percent ( %) of the time
          required to produce the deliverables, and or the value &amp; scope of
          the Services, ProCr8 Hub shall be entitled to submit a new and
          separate Proposal to Client for written approval. <br />
          Work shall not begin on the revised services until a fully signed
          revised Proposal and, if required, any additional retainer fees are
          received by ProCr8 Hub. <br /> <br />
          <h5 className="mt-2"> Client Responsibilities</h5>
          The client acknowledges that it shall be responsible for performing
          the following in a reasonable and timely manner: <br />
          <br />
          a. coordination of any decision-making with parties other than the
          ProCr8 Hub; <br />
          b. provision of Client Content in a form suitable for reproduction or
          incorporation into the Deliverables without further preparation,
          unless otherwise expressly provided in the Proposal; <br />
          c. final proofreading and if Client has approved Deliverables with
          errors, such as, typographic errors or misspellings by way of example,
          not limitation, on/in the finished product, Client shall incur the
          cost of correcting such errors; and <br />
          d. ensuring that all information and claims comprising Client Content
          are accurate, legal, and conform to applicable standards in the
          Client&rsquo;s industry. <br />
          <br />
          <h5 className="mt-2"> Attribution/Promotions </h5>
          The client agrees to include in all displays or publications of the
          Final Works attribution and/or copyright notice in ProCr8 Hub&rsquo;s
          name in the form, size, and location as incorporated by ProCr8 Hub in
          the Deliverables, or as otherwise directed by ProCr8 Hub. ProCr8 Hub
          retains the right to reproduce, publish and display the Final Works,
          Deliverables, and Preliminary Works, to the extent they do not contain
          Client&rsquo;s Confidential Information, in ProCr8 Hub&rsquo;s
          portfolios, Websites, galleries, design periodicals, and other media
          or exhibits for recognition of creative excellence or professional
          advancement, and to be credited with authorship of the Deliverables in
          connection with such uses. <br />
          Either party, subject to the other&rsquo;s reasonable approval, may
          describe its role concerning the Project, and, if applicable, the
          services provided to the other party on its Website and in other
          professional materials, and, if not expressly objected to, may include
          a link to the other party&rsquo;s Website. <br />
          <br />
          <h5 className="mt-2"> Confidential Information </h5>
          Each party acknowledges that in connection with this Agreement it may
          receive certain confidential or proprietary technical and business
          information and materials of the other party (&ldquo;Confidential
          Information&rdquo;). <br />
          Each party, its agents, and employees shall hold and maintain in
          strict confidence all Confidential Information, and shall not disclose
          Confidential Information to any third party, neither use any
          Confidential Information except as may be necessary to perform its
          obligations under the Project or as may be required by a court or
          government authority. <br />
          Notwithstanding the foregoing, Confidential Information shall not
          include any information that is in the public domain or becomes
          publicly known through no fault of the receiving party or otherwise
          properly received from a third party without an obligation of
          confidentiality. <br />
          <br />
          <h5 className="mt-2"> Warranties and Representations </h5>
          By Client. The Client represents, warrants, and covenants to ProCr8
          Hub that <br />
          <br />
          a. The Client owns all right, title, and interest in, or otherwise has
          full right and authority to permit the use of the Client Content,{" "}
          <br />
          b. to the best of the Client&rsquo;s knowledge, the Client Content is
          accurate, legal, conforms to ethical standards of the Client&rsquo;s
          industry, does not infringe the rights of any third party, and use of
          the Client Content, as well as any Trademarks in connection with the
          Project, does not and will not violate the rights of any third
          parties, <br />
          c. Client shall comply with the terms and conditions of any licensing
          agreements which govern the use of Third Party Materials, and <br />
          d. Client shall comply with all laws and regulations as they relate to
          the Services and Deliverables. <br />
          <br />
          <h5 className="mt-2"> By ProCr8 Hub</h5>
          a.ProCr8 Hub hereby represents, warrants, and covenants to Client that
          ProCr8 Hub will provide the Services in a professional and ethical
          manner and in accordance with all reasonable professional standards
          for such services. <br />
          b. ProCr8 Hub further represents, warrants and covenants to Client
          that (i) with exception of Third Party Materials and Client Content,
          the Final Deliverables shall be the original work of ProCr8 Hub (ii)
          to the best of ProCr8 Hub&rsquo;s knowledge, the Final Works
          (excluding Client Content and Third Party Materials), and use of the
          same in connection with the Project, will not violate the rights of
          any third parties. <br />
          The Client acknowledges that ProCr8 Hub will not conduct any type of
          intellectual-property clearance search (e.g., copyright, trademark,
          utility patent or design patent searches). If Client or any third
          party authorized by Client modifies or uses the Deliverables outside
          the scope of rights granted in the agreement, or otherwise in
          violation of this Agreement, all representations and warranties of
          ProCr8 Hub shall be void. <br />
          c. Except for the express representations and warranties stated in the
          agreement, ProCr8 Hub makes no warranties whatsoever. ProCr8 Hub
          explicitly disclaims any other warranties of any kind, either express
          or implied, including but not limited to; warranties of
          merchantability or fitness for a particular purpose, or compliance
          with laws, government rules and regulations applicable to the Project.{" "}
          <br />
          <br />
          <h3>
            Term and Terminations <br />
          </h3>
          <br />
          Term. This Agreement shall commence upon the Effective Date and shall
          remain effective until the Services are completed and delivered, or
          otherwise terminated as set forth herein. <br />
          Termination. This Agreement may be terminated for convenience at any
          time by either party effective immediately upon notice, or the mutual
          agreement of the parties, or for cause if any party: <br />
          <br />
          (a) breaches any of its material responsibilities or obligations under
          this Agreement, wherein breach is not remedied within ten (10) days
          from receipt of written notice of such breach. <br />
          In the event of termination, ProCr8 Hub shall be compensated for the
          Services performed through the date of termination in the amount of{" "}
          <br />
          (a) any advance payment, <br />
          (b) a prorated portion of the fees due, or <br />
          (c) hourly fees for work performed by ProCr8 Hub as of the date of
          termination, whichever is greater; and Client shall pay any
          outstanding Additional Costs, Taxes, Expenses, Charges, and costs of
          Changes incurred through the date of termination. In the event of
          termination for convenience by Client, Client shall pay in addition to
          the above an early termination fee equal to 25% of the total Project
          fee, Schedule A shall not be effective, and Client shall not have
          rights to use the Deliverables except upon written consent from ProCr8
          Hub provided after such termination. <br />
          This Agreement comprises the entire understanding of the parties
          hereto on the subject matter. It contains, supersedes, and merges all
          prior and contemporaneous agreements, understandings, and discussions
          between the parties relating to the subject matter of this Agreement.{" "}
          <br />
          In the event of a conflict between the Proposal and any other
          Agreement documents, the terms of the Proposal shall control. This
          Agreement comprises Basic Terms and Conditions document, the Proposal,
          Charges, Changes, Clients Responsibilities, Attribution/Promotions,
          Confidential Information, Warranties, and Terms &amp; Termination.{" "}
          <br />
          By their execution below, the parties hereto have agreed to all of the
          terms and conditions of this Agreement effective as of the last date
          of signature below, and each signatory represents that it has the full
          authority to enter into this Agreement and to bind her/his respective
          party to all of the terms and conditions herein
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermCondition;
