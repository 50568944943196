import React, { useState } from "react";
import "./styles.css";
import logo from "../../Assets/images/logo.png";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants";
import Twitter from "../../Assets/images/twitter.png";
import IG from "../../Assets/images/ig.png";
const Footer = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    setError("");
  }, []);
  const { name, email } = details;
  const onSubmit = (e) => {
    setError("");
    e.preventDefault();
    if (name && email) {
      setLoading(true);
      axios
        .post(`${API_URL}/newsletter`, details)
        .then((_res) => {
          if (!_res.data.error) {
            setError("Thank you for filling the form. We'll reach out ot you");
            setLoading(false);
          } else {
            setLoading(false);
            setError(_res.data.msg);
          }
        })
        .catch((_e) => {
          setLoading(false);
          setError("An Error Occured! Try Again.");
        });
    } else {
      setError("Please fill both fields");
    }
  };
  return (
    <footer className="footer">
      <div className="bg--primary">
        <div className="container">
          <div className="row py-4">
            <div className="col-md-4">
              <div className="footer-logo-con bg-white mb-2 py-2">
                <img src={logo} height="48px" alt="Procr8 logo" />
              </div>
              <p className="text-white fs-14 ">
                Our empathic nature allows us to help you tell your business
                story BEYOND how you would have.
              </p>
              <p className="text-white fs-14  pb-0 m-0">
                71, Simpson Street, Adekunle, Ebute Metta Yaba, Lagos.
              </p>
              <Link to="term-and-condition">
                <span className="text-grey">Terms & Conditions</span>
              </Link>
            </div>

            <div className="col-md-4"></div>
            <div className="col-md-4">
              <h6 className="text-bold text-white mb-4">WEEKLY NEWSLETTER</h6>
              <form onSubmit={onSubmit}>
                <input
                  type="text"
                  required
                  placeholder="Name"
                  className="footer-input p-2 pb-3 mb-2"
                  value={name}
                  onChange={(e) =>
                    setDetails((p) => ({ ...p, name: e.target.value }))
                  }
                />
                <input
                  type="email"
                  required
                  className="footer-input p-2 pb-3 mb-2"
                  placeholder="Your Email"
                  onChange={(e) =>
                    setDetails((p) => ({ ...p, email: e.target.value }))
                  }
                  value={email}
                />
                <button
                  type="submit"
                  className="p-2 py-3 m-0 bg-white fs-14 text--primary d-block cursor w-100 footer-button"
                >
                  SUBSCRIBE
                  {!loading ? null : (
                    <Spinner
                      animation="border"
                      color="#fff"
                      // variant=""
                      size="sm"
                    />
                  )}
                </button>
              </form>

              {error ? (
                <p
                  className="mt-3 font-weight-bold"
                  style={{
                    color:
                      error ===
                      "Thank you for filling the form. We'll reach out ot you"
                        ? "green"
                        : "#fff",
                    textAlign: "center",
                  }}
                >
                  {error}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="bg--black">
        <div className="container footer-socials">
          <div className="d-flex justify-content-between">
            <span>© All rights reserved</span>
            <span>
              <ul className="list">
                <li>
                  <a
                    href="https://twitter.com/Procr8_hub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Procr8 Hub Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/procr8_hub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={IG} alt="Procr8 Hub Instagram" />
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
