import React from "react";
import Comp from "../Components/Project";
import Nav from "../Components/NavBar";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle";

const Project = () => {
  return (
    <div>
      <PageTitle title="Procr8hub | Hire us" />
      <Nav />
      <Comp />
      <Footer />
    </div>
  );
};

export default Project;
