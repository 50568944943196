import React from "react";
import Fade from "react-reveal";
import "./styles.css";

import I1 from "../../Assets/images/1.png";
import I2 from "../../Assets/images/2.png";
import I3 from "../../Assets/images/3.png";
import I4 from "../../Assets/images/4.png";
import I5 from "../../Assets/images/5.png";
import I6 from "../../Assets/images/6.png";
import I7 from "../../Assets/images/7.png";
import I8 from "../../Assets/images/8.png";
import I9 from "../../Assets/images/9.png";
import I10 from "../../Assets/images/10.png";
import I11 from "../../Assets/images/11.png";
import I12 from "../../Assets/images/12.png";
import I13 from "../../Assets/images/13.png";
import I14 from "../../Assets/images/14.png";
import I15 from "../../Assets/images/15.png";
import I16 from "../../Assets/images/16.png";
import I17 from "../../Assets/images/17.png";
import I18 from "../../Assets/images/18.png";
import I19 from "../../Assets/images/19.png";
import I20 from "../../Assets/images/20.png";
import I21 from "../../Assets/images/21.png";
import I22 from "../../Assets/images/22.png";
import I23 from "../../Assets/images/23.png";
import I24 from "../../Assets/images/24.png";
import I25 from "../../Assets/images/25.png";
import I26 from "../../Assets/images/26.png";
import I27 from "../../Assets/images/27.png";
import I28 from "../../Assets/images/28.png";
import I29 from "../../Assets/images/29.png";
import I30 from "../../Assets/images/30.png";

const Portfolio = () => {
  const itemList = [
    {
      img: I1,
      Name: "Name 1",
    },
    {
      img: I2,
      Name: "Name 2",
    },
    {
      img: I3,
      Name: "Name 3",
    },
    {
      img: I4,
      Name: "Name 4",
    },
    {
      img: I5,
      Name: "Name 4",
    },
    {
      img: I6,
      Name: "Name 4",
    },
    {
      img: I7,
      Name: "Name 4",
    },
    {
      img: I8,
      Name: "Name 4",
    },
    {
      img: I9,
      Name: "Name 4",
    },
    {
      img: I10,
      Name: "Name 4",
    },
    {
      img: I11,
      Name: "Name 4",
    },
    {
      img: I12,
      Name: "Name 4",
    },
    {
      img: I13,
      Name: "Name 4",
    },
    {
      img: I14,
      Name: "Name 4",
    },
    {
      img: I15,
      Name: "Name 4",
    },
    {
      img: I16,
      Name: "Name 4",
    },
    {
      img: I17,
      Name: "Name 4",
    },
    {
      img: I18,
      Name: "Name 4",
    },
    {
      img: I19,
      Name: "Name 4",
    },
    {
      img: I20,
      Name: "Name 4",
    },
    {
      img: I21,
      Name: "Name 4",
    },
    {
      img: I22,
      Name: "Name 4",
    },
    {
      img: I23,
      Name: "Name 4",
    },
    {
      img: I24,
      Name: "Name 4",
    },
    {
      img: I25,
      Name: "Name 4",
    },
    {
      img: I26,
      Name: "Name 4",
    },
    {
      img: I27,
      Name: "Name 4",
    },
    {
      img: I28,
      Name: "Name 4",
    },
    {
      img: I29,
      Name: "Name 4",
    },
    {
      img: I30,
      Name: "Name 4",
    },
  ];

  return (
    <div className="container">
      <div className="row mt-5">
        {itemList.map((item, i) => (
          <div className="col-md-4 mt-4 mb-5 ">
            <Fade>
              <img
                key={i}
                src={item.img}
                alt="ProCr8 Hub Projects"
                className="mobile-size"
              />
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
