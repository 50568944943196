import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Portfolio from "./Pages/Portfolio";
import Services from "./Pages/Services";
import Marketing from "./Pages/Marketing";
import Academics from "./Pages/Academics";
import Testimonials from "./Pages/Testimonials";
import Pricing from "./Pages/Pricing";
import Career from "./Pages/Career";
import GetStarted from "./Pages/GetStarted";
import About from "./Pages/About";
import TermCondition from "./Pages/TermCondition";
import Project from "./Pages/Project";
import ScrollToTop from "./Components/ScrollToTop";
import WA from "./Assets/images/whatsapp.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/marketing" component={Marketing} />
            <Route exact path="/academics" component={Academics} />
            <Route exact path="/testimonials" component={Testimonials} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/project" component={Project} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/get-started" component={GetStarted} />
            <Route exact path="/about" component={About} />
            <Route exact path="/term-and-condition" component={TermCondition} />
          </Switch>
        </ScrollToTop>
      </Router>
      <a
        href="https://wa.me/2348093206395?text=Hello%20Procr8Hub!"
        target="blank"
        className="whatsapp-wrapper"
      >
        <img src={WA} alt="" className="whatsapp" />
      </a>
    </>
  );
};

export default App;
