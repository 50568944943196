import React, { Component } from "react";
import Slider from "react-slick";
import "./styles.css";

import B1 from "../../Assets/images/test1.png";
import B2 from "../../Assets/images/test2.png";
import B3 from "../../Assets/images/test3.png";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
    };
    return (
      <>
        <div className="container mt-5">
          <h3 className="our-story">Testimonials</h3>
          <p className="our-story-desc">
            We are a full-service branding, design and development company, we
            help you bring your personal brand ideas, event dreams and business
            ideas to life. We offer a complete range of creative services that
            inspire, influence, impact and convert at every touch point. Our
            services cut accross Content and Research, Corporate Branding,
            Business Branding, Product Branding and Packaging, Mobile
            App/Website Design and Development, Event Branding, Training,
            Internship, Design and Print Consultation. We work with every client
            from the ideation stage to Management. Well; talk is cheap, let’s
            work with you for a thrilling experience.
          </p>
        </div>
        <div className="container mt-5 remv">
          <h2 className="text-center">Why Us?</h2>
          <section className="text-center">
            We provide impeccablesolution serviceshat is UNIQUE to your brand,
            stands out and gets results that are beyond expectations
          </section>
          <Slider {...settings} className="mt-3">
            <div>
              <img src={B1} alt="" className="w-100" />
            </div>
            <div>
              <img src={B2} alt="" className="w-100" />
            </div>
            <div>
              <img src={B3} alt="" className="w-100" />
            </div>
          </Slider>
        </div>
      </>
    );
  }
}
