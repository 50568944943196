import React from "react";
import Comp from "../Components/Testimonials";
import Nav from "../Components/NavBar";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle";
const About = () => {
  return (
    <div>
      <PageTitle title="Procr8hub | About Us" />
      <Nav />
      <Comp />
      <Footer />
    </div>
  );
};

export default About;
