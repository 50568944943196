import React from "react";
import "./styles.css";
import {
  Navbar,
  NavDropdown,
  Nav,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/images/logo.png";

const NavBar = () => {
  return (
    <div>
      <div className="bg--primary">
        <div className="container d-flex py-2 text-white fs-14 text-italic">
          <p className="m-0 mr-3">Tel: 07082239086</p>
          <p className="m-0">E-mail: info@procr8hub.com</p>
        </div>
      </div>

      <Navbar className="navbar-container" expand="lg">
        <div className="container">
          {/* <Navbar.Brand href="#home"></Navbar.Brand> */}
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Procr8hub logo" height="48px" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto ">
              <NavDropdown
                title="Explore"
                className="text--primary"
                id="basic-nav-dropdown"
                color="#ED3237"
                as="p"
              >
                <NavLink
                  activeClassName="active"
                  to="/portfolio"
                  className="dropdown-item"
                >
                  Portfolio
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/testimonials"
                  className="dropdown-item"
                >
                  Testimonials
                </NavLink>
                <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/services"
                  className="dropdown-item"
                >
                  Services
                </NavLink>
                <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/marketing"
                  className="dropdown-item"
                >
                  Marketing
                </NavLink>
                {/* <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/products"
                  className="dropdown-item"
                >
                  Products
                </NavLink> */}
                <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/academics"
                  className="dropdown-item"
                >
                  Academics
                </NavLink>
                {/* <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/Subscription"
                  className="dropdown-item"
                >
                  Sub Packages
                </NavLink> */}
                <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/career"
                  className="dropdown-item"
                >
                  Join Us
                </NavLink>
              </NavDropdown>
              {/* <NavDropdown
                title="About"
                className="text--primary"
                id="basic-nav-dropdown"
                color="#ED3237"
                as="p"
              > */}
              {/* <NavLink
                  activeClassName="active"
                  to="/portfolio"
                  className="dropdown-item"
                >
                  About Us
                </NavLink> */}
              {/* <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/testimonial"
                  className="dropdown-item"
                >
                  Testimonial 
                </NavLink>*/}
              {/* <NavDropdown.Divider className="m-0" /> */}
              {/* <NavLink
                activeClassName="active"
                to="/marketing"
                className="dropdown-item"
              >
                Meet The Team
              </NavLink> */}
              {/* <NavDropdown.Divider className="m-0" />
                <NavLink
                  activeClassName="active"
                  to="/products"
                  className="dropdown-item"
                >
                  Gallery
                </NavLink> */}
              <NavDropdown.Divider className="m-0" />
              {/* <NavLink
                  activeClassName="active"
                  to="/academics"
                  className="dropdown-item"
                >
                  Terms and Conditions
                </NavLink> */}
              {/* </NavDropdown> */}

              <Link className="nav-link" to="/career">
                Join Us
              </Link>
              <Link className="nav-link" to="/about">
                About us
              </Link>
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
              <Link className="nav-link" to="/project">
                Hire us
              </Link>
              <div className="vertical mr-4" />
              <DropdownButton
                className="navbar-button"
                id="dropdown-basic-button"
                title="Contact Us"
                variant="danger"
                menuAlign="right"
              >
                <Dropdown.Item href="Tel: 08093206395">
                  <span className="text-bold">Call:</span> 08093206395
                </Dropdown.Item>
                <NavDropdown.Divider className="m-0" />
                <Dropdown.Item href="Email: info@procr8hub.com">
                  <span className="text-bold">Email:</span> info@procr8hub.com
                </Dropdown.Item>
                <NavDropdown.Divider className="m-0" />
                {/* <NavLink
                  activeClassName="active"
                  to="/marketing"
                  className="dropdown-item"
                >
                  Meet The Team
                </NavLink> */}
                <NavDropdown.Divider className="m-0" />
              </DropdownButton>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
