import React, { useState } from "react";
import "./styles.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../constants";

export default function Career(props) {
  //   const [loading, setLoading] = useState(true);
  //   setInterval(() => setLoading(false), 4000);
  const [formInput, setFormInput] = useState({
    fullname: "",
    email: "",
    phonenum: "",
    resume: null,
    coverletter: "",
  });
  const [message, setMessage] = useState("");
  const [loading2, setLoading2] = useState(false);
  const handleSumbit = (e) => {
    setMessage("");
    e.preventDefault();
    const { fullname, email, phonenum, resume, coverletter } = formInput;
    if (fullname && email && phonenum && resume) {
      setLoading2(true);
      const formData = new FormData();
      formData.append("fullName", fullname);
      formData.append("email", email);
      formData.append("phone", phonenum);
      formData.append("coverletter", coverletter);
      formData.append("resume", resume);

      axios
        .post(`${API_URL}/career`, formData)
        .then((_res) => {
          // console.log(res)
          setMessage("Thank you for applying the form. We'll reach out to you");
          setLoading2(false);
          // props.history.push(`/confirmation/name=${formInput.fullname}`);
          setFormInput({
            fullname: "",
            email: "",
            phonenum: "",
            resume: null,
            coverletter: "",
            projectdoc: "",
          });
        })
        .catch((_e) => {
          setLoading2(false);
          setMessage("An Error Occured! Try Again.");
        });
    } else setMessage("Please fill the fields above");
  };

  return (
    <>
      <Container fluid className="formwrapper pb-5 whitebg blackText">
        <Container>
          <Row className="mt-5">
            <Col md={8}>
              <Col className="a-team-card-holder pushtop mt-5">
                <div className="bigText mt-4">We'll love to have you too</div>
                <p className="smallestText b4 montserrat mt-2">
                  Complete this form and we'll reach out ot you
                </p>
              </Col>
              <Col className="a-team-card-holder">
                <form
                  action=""
                  className="mt-5 pb-5 montserrat whiteText"
                  onSubmit={(e) => handleSumbit(e)}
                >
                  <Row>
                    <Col md={8}>
                      <p className="bold blackText">Full Name</p>
                      <input
                        type="text"
                        placeholder="Your name goes here"
                        className="blackinput smallestText"
                        required
                        value={formInput.fullname}
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            fullname: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={8}>
                      <p className="bold blackText">Email Address</p>
                      <input
                        type="email"
                        placeholder="youremail@domain.com"
                        className="blackinput smallestText"
                        required
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={4}>
                      <p className="bold blackText">Phone Number</p>
                      <input
                        type="tel"
                        placeholder="+123 4567 890"
                        className="blackinput smallestText"
                        required
                        value={formInput.phonenum}
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            phonenum: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <p className="bold smpt blackText sp">Upload Resume</p>
                      <input
                        type="file"
                        className="file"
                        id="file"
                        accept="images/*, .pdf, .doc, .docx"
                        required
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            resume: e.target.files[0],
                          })
                        }
                      />
                      {formInput.resume ? (
                        <label
                          htmlFor="file"
                          className="smallestText media label"
                        >
                          {String(formInput.resume.name).substr(0, 15) + "..."}
                        </label>
                      ) : (
                        <label
                          htmlFor="file"
                          className="smallestText media label"
                        >
                          Choose File
                        </label>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={8}>
                      <p className="blackText bold">Cover Letter</p>
                      <textarea
                        className="blackinput smallestText textarea"
                        placeholder="what else would you like us to know?"
                        required
                        value={formInput.coverletter}
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            coverletter: e.target.value,
                          })
                        }
                      />

                      <button
                        type="submit"
                        className="submitButton mt-3 black button"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                  {!loading2 ? (
                    message ? (
                      <p
                        className="font-weight-bold"
                        style={{
                          color:
                            message ===
                            "Thank you for filling the form. We'll reach out ot you"
                              ? "green"
                              : "red",
                          textAlign: "center",
                        }}
                      >
                        {message}
                      </p>
                    ) : null
                  ) : (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                </form>
              </Col>
            </Col>
            <Col md={4} className="ben"></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
