import React from "react";
import Fade from "react-reveal/Fade";
import "./styles.css";

import img1 from "../../Assets/images/t1.png";
import img2 from "../../Assets/images/t2.png";
import img3 from "../../Assets/images/t3.png";
import img4 from "../../Assets/images/t4.png";
import swe from "../../Assets/images/tttt.png";
import eee from "../../Assets/images/eee.png";

import tr1 from "../../Assets/images/tr1.png";
import tr2 from "../../Assets/images/tr2.png";
import tr3 from "../../Assets/images/tr3.png";
import tr4 from "../../Assets/images/tr4.png";

import w1 from "../../Assets/images/w1.png";
import w2 from "../../Assets/images/w2.png";
import w3 from "../../Assets/images/w3.png";
import w4 from "../../Assets/images/w4.png";

const AcademicsComp = () => {
  const tutorials = [
    {
      img: img1,
      title: "Basic Principle of Design",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    },
    {
      img: img2,
      title: "Figma for Graphics",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .",
    },
    {
      img: swe,
      title: "Introduction to Branding",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .",
    },
    {
      img: img3,
      title: "Figma Made Easy",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .",
    },

    {
      img: img4,
      title: "PhotoShop for Beginners",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .",
    },
    {
      img: eee,
      title: "Software Development for Beginners",
      pricing: "Free",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .",
    },
  ];

  const trainings = [
    {
      img: tr1,
      title: "Graphics Design",
      pricing: "60,000",
      timing: "2 months",
      desc:
        "Learn the basic visual content creation to help communicate your ideas.",
    },
    {
      img: tr2,
      title: "Product Design",
      pricing: "120,000",
      timing: "2 months",
      desc:
        "Learn the process of imaginning, creating and iterating products that solve user problems or address specific needs in a given market.",
    },
    {
      img: tr3,
      title: "Photography",
      pricing: "60,000",
      timing: "2 months",
      desc: "Learn the art of photography from beginner to pro.",
    },
    {
      img: tr4,
      title: "Cinematography",
      pricing: "60,000",
      timing: "2 months",
      desc: "Learn the art of motion picture photography and filming.",
    },
  ];

  const webinars = [
    {
      img: w1,
      title: "Customer Development",
      pricing: "10,000",
      timing: "3 days",
      desc:
        "Learn the formal process of identifying potential customers and figuring out how to meet there needs i.e, discovering, testing and validating their assumptions.",
    },
    {
      img: w2,
      title: "Design-Preneurship ",
      pricing: "10,000",
      timing: "3 days",
      desc: "Learn about the money making skill (entrepreneurship) in design.",
    },
    {
      img: w3,
      title: "Selling Skill",
      pricing: "10,000",
      timing: "3 days",
      desc:
        "Learn about the psychology of sales, understand what the buyer wants and how to sell in a responsive manner.",
    },
    {
      img: w4,
      title: "Using Excel",
      pricing: "10,000",
      timing: "3 days",
      desc:
        "Learn to use the excel software program to organize, format and calculate data with formulas using a spreadsheet system.",
    },
  ];

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <p className="pcr-section-header">Academics</p>
        </div>
        <div className="col-md-8">
          <div className="pcr-section-desc">
            Start your journey to learn the skill of learning new skills with
            the best facilitators.
          </div>
        </div>
      </div>
      <p className="mt-5 pcr-academics-heading">Tutorials</p>
      <div className="row">
        {tutorials.map((item, i) => (
          <Fade>
            <div className="col-md-4 mt-5" key={i}>
              <div>
                <img
                  src={item.img}
                  alt="ProCr8 Hub Projects"
                  className=" mobile-size w-100"
                />
              </div>
              <div>
                <p className="pcr-section-title push-top">{item.title}</p>
                <section className="red">{item.pricing}</section>
                {/* <section>{item.desc}</section> */}
                <a
                  href="https://wa.me/2348093206395?text=Hello%20Procr8Hub%20I'm%20Interested%20in%20the%20Tutorials!"
                  target="blank"
                  className="whatsapp-wrapper"
                >
                  <button className="pcr-get-started">Enroll Now</button>
                </a>
              </div>
            </div>
          </Fade>
        ))}
      </div>

      <p className="mt-5 pcr-academics-heading mt-2">Trainings</p>
      <div className="row">
        {trainings.map((item, i) => (
          <Fade>
            <div className="col-md-3 sm-push-top" key={i}>
              <div>
                <img
                  src={item.img}
                  alt="ProCr8 Hub Projects"
                  className=" mobile-size w-100"
                />
              </div>
              <div>
                <p className="pcr-section-title push-top">{item.title}</p>
                <span className="red">{item.timing}</span>
                <section className="red">₦{item.pricing}</section>
                <section>{item.desc}</section>

                <a
                  href="https://wa.me/2348093206395?text=Hello%20Procr8Hub%20I'm%20Interested%20in%20the%20Tranings!"
                  target="blank"
                  className="whatsapp-wrapper"
                >
                  <button className="pcr-get-started">Enroll Now</button>
                </a>
              </div>
            </div>
          </Fade>
        ))}
      </div>

      <p className="mt-5 pcr-academics-heading mt-2">Webinar Courses</p>
      <div className="row">
        {webinars.map((item, i) => (
          <Fade>
            <div className="col-md-3 sm-push-top" key={i}>
              <div>
                <img
                  src={item.img}
                  alt="ProCr8 Hub Projects"
                  className=" mobile-size w-100"
                />
              </div>
              <div>
                <p className="pcr-section-title push-top">{item.title}</p>
                <span className="red">{item.timing}</span>
                <section className="red">₦{item.pricing}</section>
                <section>{item.desc}</section>
                <a
                  href="https://wa.me/2348093206395?text=Hello%20Procr8Hub%20I'm%20Interested%20in%20the%20Webinar%20Courses!"
                  target="blank"
                  className="whatsapp-wrapper"
                >
                  <button className="pcr-get-started">Enroll Now</button>
                </a>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default AcademicsComp;
