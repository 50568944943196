import React from "react";
import { Link } from "react-router-dom";
// import Img from "../../Assets/images/s1.png";
import Img1 from "../../Assets/images/s2.png";
// import Img3 from "../../Assets/images/adbox.png";
import Fade from "react-reveal/Fade";
import "./styles.css";

import I1 from "../../Assets/images/os1.png";
import I2 from "../../Assets/images/os2.png";
import I3 from "../../Assets/images/os3.png";
import I4 from "../../Assets/images/os4.png";
import I5 from "../../Assets/images/os5.png";
import I6 from "../../Assets/images/os6.png";
import I7 from "../../Assets/images/os7.png";
import I8 from "../../Assets/images/os8.png";
import I9 from "../../Assets/images/os9.png";

const OurStory = () => {
  const data = [
    {
      num: "1",
      heading: "Design",
      content: "We are a full-service branding design and development company",
      src: I1,
    },
    {
      num: "2",
      heading: "Marketing ",
      content:
        "We promote and selling products or services, including market research and advertising",
      src: I2,
    },
    {
      num: "3",
      heading: "Branding",
      content: "We are a full-service branding design and development company",
      src: I3,
    },
    {
      num: "4",
      heading: "Content Creation",
      content: "We are a full-service branding design and development company",
      src: I4,
    },
    {
      num: "5",
      heading: "Software Development",
      content: "We are a full-service branding design and development company",
      src: I5,
    },
    {
      num: "6",
      heading: "Teaching ",
      content: "We are a full-service branding design and development company",
      src: I6,
    },
    {
      num: "7",
      heading: "Research",
      content: "We are a full-service branding design and development company",
      src: I7,
    },
    {
      num: "8",
      heading: "Product Sale",
      content: "We are a full-service branding design and development company",
      src: I8,
    },
    {
      num: "9",
      heading: "Photography",
      content: "We are a full-service branding design and development company",
      src: I9,
    },
  ];

  return (
    <>
      <div className="container pcr-os-section">
        <h3 className="our-story">Our Story</h3>
        <p className="our-story-desc">
          We are a full-service branding, design and development company, we
          help you bring your personal brand ideas, event dreams and business
          ideas to life. We offer a complete range of creative services that
          inspire, influence, impact and convert at every touch point. Our
          services cut accross Content and Research, Corporate Branding,
          Business Branding, Product Branding and Packaging, Mobile App/Website
          Design and Development, Event Branding, Training, Internship, Design
          and Print Consultation. We work with every client from the ideation
          stage to Management. Well; talk is cheap, let’s work with you for a
          thrilling experience.
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="pcr-os-header">Our Services</div>
            <div className="pcr-os-desc">We are a Super Creative Agency</div>
          </div>
          <div className="col-md-8">
            <Fade>
              {/* <p className="">
                We are a full-service branding, design and development company,
                we help you bring your personal brand ideas, event dreams and
                business ideas to life. We offer a complete range of creative
                services that inspire, influence and impact at every touch
                point. Our services cut accross Research, Content Creation,
                Corporate Branding, Business Branding, Product Packaging,
                App/Web Design and Development, Event Branding, Training,
                Internship, Design and Print Consultation. For any of the above
                mentioned, search no more
              </p> */}
            </Fade>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {data.map((item, i) => (
            <div className="col-md-4 pcr-service" key={i}>
              <div>
                <section className="number">
                  <img src={item.src} className="w-100" alt="" />
                </section>
                <p className="pcr-services-heading">{item.heading}</p>
                {/* <section className="grey">{item.content}</section> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container packaging-section">
        <div className="row">
          <div className="col-md-6 left-section">
            <p className="pcr-print-header">Printing and Product Packaging</p>
            <div className="pcr-print-desc">
              Whether a startup or a global legacy, we'll develop a visual brand
              identity that resonates with your target audience, and achieves
              your positioning goals. We cover both B2C and B2C projects and
              logos, brand identity booklets, packaging, social media,
              billboards, emails, and much more.
            </div>
            <Link to="/project">
              <button className="pcr-print-order">Let's Talk Business</button>
            </Link>
          </div>
          <div className="col-md-6">
            <img src={Img1} alt="Procr8Hub" className="w-100 sm-push-top" />
          </div>
        </div>
        {/* <div className="row"> */}
        {/* <div className="col-md-6 pcr-print-section">
        </div>
        <div className="col-md-6 pcr-print-bg">
        </div> */}
        {/* </div> */}
      </div>

      {/* <div className="container pcr-print-section pt-5 pb-5">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-4">
            <img src={Img3} alt="Procr8Hub" className="w-100" />
          </div>
          <div className="col-md-4 pcr-print-section">
            <div>
              Experience the luxury of how design and print should be delivered
              in time and faultless
            </div>
            <button className="pcr-print-order">Get Started</button>
          </div>
          <div className="col-md-2" />
        </div>
      </div> */}
      {/* <div className="container pcr-print-section pt-5 pb-5"> */}
      {/* <div className="text-center">Why Us?</div>
        <div className="pcr-why-us">
          We provide impeccable brand solutions services that is UNIQUE to your
          brand, stands out and get results that are beyond expectations.
        </div> */}
      {/* <div className="row">
          <div className="col-md-2" />
          <div className="col-md-4">
            <img src={Img3} alt="Procr8Hub" className="w-100" />
          </div>
          <div className="col-md-4 pcr-print-section"></div>
          <div className="col-md-2" />
        </div> */}
      {/* </div> */}
    </>
  );
};

export default OurStory;
