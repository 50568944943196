import React from "react";
import SideImage from "../../Assets/images/more.png";
import "./styles.css";

const More = () => {
  return (
    <div className="container pcr-more-section">
      <div className="row">
        <div className="col-md-6">
          <img
            src={SideImage}
            alt="ProCr8"
            className="pcr-more-sideimage w-100"
          />
        </div>
        <div className="col-md-6 prc-more-left">
          <p className="pcr-more-header">The Creative Crib</p>
          <section>
            Join The Creative Crib for a skill discovery session and project
            collaboration with capable hands.
          </section>
          <a href="https://wa.me/2348093206395?text=Hello%20Procr8Hub">
            <button>Register</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default More;
