import React from "react";
import S1 from "../../Assets/images/sub1.png";
import S2 from "../../Assets/images/sub2.png";
import S3 from "../../Assets/images/sub3.png";
import "./styles.css";

const Subscription = () => {
  const section1 = [
    {
      img: S1,
      title: "Content Writing",
      desc:
        "We write for you, your captions, your newsletters, your blogs and articles.",
    },
    {
      img: S2,
      title: "Content Design",
      desc:
        "We design every content you create or content we develop for you into graphics.",
    },
    {
      img: S3,
      title: "Social Media Management",
      desc:
        "We manage social media pages and interact with your audience on your behalf.",
    },
  ];

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <p className="pcr-section-header">Subscription Packages</p>
        </div>
        <div className="col-md-8">
          <div className="pcr-section-desc">
            By combining expert knowledge, great designs and professional
            ongoing support, you will get results — guaranteed. Don’t just take
            our word for it, hear what our past clients had to say.h
          </div>
        </div>
      </div>

      <div className="row mt-5">
        {section1.map((item, i) => (
          <div className="col-md-4 sm-push-top" key={i}>
            <img src={item.img} alt="Procr8 hub Project" className="w-100" />
            <p className="pcr-sub-title">{item.title}</p>
            <section>{item.desc}</section>
          </div>
        ))}
      </div>

      <p className="pcr-sub-header mt-5">
        Social Media Management Subscription Plans
      </p>
      <div>Affordable pricing for everyone</div>

      <div className="row">
        <div className="col-md-4 mt-4">
          <div className="pcr-section-card">
            <p className="pcr-item-plan">Basic Plan</p>
            <div>
              <div>Instagram and Twitter</div>
              <div>
                <b>5</b> engaging posts weekly
              </div>
              <div>Instagram stories inclusive</div>
              <div className="line-through">Sponsored Promotional Ads</div>
              <div className="line-through">Video content materials</div>
              <div className="line-through">Facebook stories inclusive</div>
              <div className="line-through">Facebook page engagement</div>
              <div className="line-through">Dedicated page manager</div>
            </div>

            <p className="pcr-card-item">Design Subscription</p>
            <div>20 orders with moderate review</div>
            <div>1 order at a time</div>
            <div>24 - 48 hours update</div>

            <p className="pcr-card-item">Content Writing</p>
            <div>
              1500 word count <br /> (Social Media Content Writeup)
            </div>
            <div className="pcr-item-pricing"> ₦70,000</div>
            <div className="red">per month</div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="pcr-section-card">
            <p className="pcr-item-plan">Premium Plan</p>
            <div>
              <div>Instagram and Twitter</div>
              <div>
                <b>10</b> engaging posts weekly
              </div>
              <div>Instagram stories inclusive</div>
              <div>Sponsored Promotional Ads</div>
              <div>1 Video content materials</div>
              <div className="line-through">Facebook stories inclusive</div>
              <div className="line-through">Facebook page engagement</div>
              <div className="line-through">Dedicated page manager</div>
            </div>

            <p className="pcr-card-item">Design Subscription</p>
            <div>30 orders with moderate review</div>
            <div>2 orders at a time</div>
            <div>24 - 48 hours update</div>

            <p className="pcr-card-item">Content Writing</p>
            <div>
              3000 word count <br /> (Social Media Content Writeup)
            </div>
            <div className="pcr-item-pricing"> ₦120,000</div>
            <div className="red">per month</div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="pcr-section-card">
            <p className="pcr-item-plan">Pro Plan</p>
            <div>Instagram and Twitter</div>
            <div>
              <b>20</b> engaging posts weekly
            </div>
            <div>Instagram stories inclusive</div>
            <div>Sponsored Promotional Ads</div>
            <div>2 Video content materials</div>
            <div>Facebook stories inclusive</div>
            <div>Facebook page engagement</div>
            <div>Dedicated page manager</div>

            <p className="pcr-card-item">Design Subscription</p>
            <div>40 orders with moderate review</div>
            <div>Priority support</div>
            <div>24 - 48 hours update</div>

            <p className="pcr-card-item">Content Writing</p>
            <div>
              6000 word count <br /> (Social Media Content Writeup)
            </div>
            <div className="pcr-item-pricing"> ₦170,000</div>
            <div className="red">per month</div>
          </div>
        </div>
      </div>

      <p className="pcr-sub-header mt-5">
        Flyer/E-Flyer Design Subscription Plans
      </p>
      <div>Affordable pricing for everyone</div>

      <div className="row">
        <div className="col-md-3">
          <div className="pcr-section-card">
            <p className="pcr-item-plan-head">Basic Plan</p>
            <div>Promotional Flyer / e-flyer</div>
            <div>Program flyer/e-flyer</div>
            <div>Celebration flyer/e-flyer</div>
            <div className="big-red-text">₦4,000</div>
            <div className="red">per flyer</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="pcr-section-card">
            <p className="pcr-item-plan-head">Basic Sub Plan</p>
            <div>Promotional Flyer / e-flyer</div>
            <div>Program flyer/e-flyer</div>
            <div>Celebration flyer/e-flyer</div>
            <div className="big-red-text">₦2,000</div>
            <div className="red">per flyer</div>
            <div>MOQ30 flyers</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="pcr-section-card">
            <p className="pcr-item-plan-head">Premium Plan</p>
            <div>Promotional Flyer / e-flyer</div>
            <div>Program flyer/e-flyer</div>
            <div>Celebration flyer/e-flyer</div>
            <div className="big-red-text">₦1,000</div>
            <div className="red">per flyer</div>
            <div>MOQ180 flyers</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="pcr-section-card">
            <p className="pcr-item-plan-head">Pro Plan</p>
            <div>Promotional Flyer / e-flyer</div>
            <div>Program flyer/e-flyer</div>
            <div>Celebration flyer/e-flyer</div>
            <div className="big-red-text">₦700</div>
            <div className="red">per flyer</div>
            <div>Terms and Conditions apply</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
